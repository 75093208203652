import { Flex, Icon, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { BsCheck2All } from 'react-icons/bs'
import { FaRegAddressCard } from 'react-icons/fa'
import { MdOutlineCoPresent } from 'react-icons/md'
import { TbUsersGroup } from 'react-icons/tb'

function LeftMenu({ tab, setTab, isAdmin }) {
  return (
    <Flex bg='white' h='calc(100vh - 70px)' w='100px' maxW='100px' zIndex={1}>
      <Stack spacing={0}>
        {[
          {
            icon: MdOutlineCoPresent,
            title: 'Principal',
            name: 'principal',
            isAdminOnly: false,
          },
          {
            icon: FaRegAddressCard,
            title: 'Painel de Credenciamento',
            name: 'credenciamento',
            isAdminOnly: false,
          },
          {
            icon: BsCheck2All,
            title: 'Painel de Votações',
            name: 'votacao',
            isAdminOnly: false,
          },
          {
            icon: TbUsersGroup,
            title: `Credores`,
            name: 'credores',
            isAdminOnly: true,
          },
          {
            icon: MdOutlineCoPresent,
            title: 'Documentos Importantes',
            name: 'docs',
            isAdminOnly: false,
          },
        ]
          .filter((item) => !item.isAdminOnly || isAdmin)
          .map((item, index) => {
            const active = item.name === tab

            return (
              <Stack
                userSelect='none'
                _hover={{
                  bg: '#ddd',
                  cursor: 'pointer',
                }}
                transition='all 0.2s ease-in-out'
                onClick={() => {
                  setTab(item.name)
                }}
                key={String(`${index}a`)}
                bg={active ? '#FDCA5F' : 'white'}
                color={active ? '#000000' : '#222222'}
                align='center'
                py='20px'
                spacing={0}
              >
                <Icon as={item.icon} fontSize='26px' />
                <Text textAlign='center' fontSize='11px' px='5px'>
                  {item.title}
                </Text>
              </Stack>
            )
          })}
      </Stack>
    </Flex>
  )
}

export default LeftMenu
